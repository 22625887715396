<template>
  <div
    class="container main-content"
    v-loading="loading"
    element-loading-text="正在保存，请稍等。。。"
    element-loading-spinner="el-icon-loading"
  >
    <div class="crt-content">
      <div class="crt-content-left">
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-edit"
          class="commonBtn"
          @click="changeBasicInfo"
        >
          {{ isResearch ? '编辑' : '取消' }}
        </el-button>
        <el-button
          v-if="!isResearch"
          :disabled="disabled"
          :type="type"
          size="mini"
          icon="el-icon-edit"
          class="commonBtn"
          @click="saveBasicInfo"
        >
          保存
        </el-button>
        <div class="block">
          <span class="demonstration">出院时间</span>
          <el-date-picker
            v-model="info.dischargeDate"
            @change="getLeaveHospitalTime"
            align="center"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          ></el-date-picker>
          <span style="margin-left: 25px">住院时长: {{ getHospitalTime() }}</span>
        </div>
      </div>

      <div class="crt-content-right">
        <el-button size="mini" icon="el-icon-plus" @click="handleLeading('auto')">
          自动引入
        </el-button>
        <el-button size="mini" icon="el-icon-plus" @click="handleLeading">导入LIS</el-button>
        <el-button size="mini" icon="el-icon-plus" @click="handlePACS">导入检查报告</el-button>
        <el-button size="mini" icon="el-icon-plus" @click="handleUpPACS">上传本地PDF</el-button>
        <el-button size="mini" icon="el-icon-tickets" @click="getQueryInfo2">基线比对</el-button>
        <el-upload
          ref="upload"
          style="display: none"
          action=""
          :show-file-list="false"
          :http-request="pdfImport"
          multiple
        >
          <el-button size="mini" type="primary">上传本地PDF</el-button>
        </el-upload>
      </div>
    </div>
    <el-card class="body">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, index) in list"
          :key="item.id"
          :label="getLabel(item)"
          :name="String(index)"
        >
          <div v-if="dataList.length">
            <readonly-tab
              :isReadonly="isReadonly"
              :ref="'tab' + index"
              :info="info"
              :tabInfo="{ ...item, ...dataList[Number(index)] }"
              :opStage="'LEAVE'"
              :data="dataList[Number(index)]?.tab"
              :dataUuid="handleItemUuid"
              :examinationInfo="dataList[Number(index)]?.examinationInfo"
              @closeLoading="isLoading"
              @save="saveEFormInfo"
              @handleDownload="handleDownloadPdf"
              @delExamine="delExamine"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <el-dialog
      width="30%"
      title="检查类型选择"
      class="add-pacs"
      :visible.sync="pacsTypeInnerVisible"
    >
      <div class="">
        <span>请选择检查类型：</span>
        <el-select v-model="PACSTypeCode" placeholder="请选择" @change="handlePACSTemplet">
          <el-option
            v-for="item in PACSOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </el-dialog>
    <el-dialog
      width="30%"
      title="表单引入"
      class="add-form auto-leading"
      :visible.sync="innerVisibleAutoLeading"
    >
      <div class="dialog-btn-wrap">
        <template>
          <el-checkbox v-model="syncFirstStatus" style="margin-right: 10px">
            查询前先同步LIS
          </el-checkbox>
          <el-button
            type="primary"
            size="mini"
            class="commonBtn"
            @click="autoLeading"
            icon="el-icon-search"
          >
            确认
          </el-button>
        </template>
      </div>
    </el-dialog>

    <el-dialog width="60%" title="表单引入" class="add-form" :visible.sync="innerVisible">
      <div class="dialog-btn-wrap">
        <template>
          按日期查询：
          <el-date-picker
            v-model="dialogQueryDate"
            type="daterange"
            align="center"
            size="mini"
            style="margin-right: 10px"
            unlink-panels
            @change="handleDialogQueryDate"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          ></el-date-picker>
          <el-checkbox v-model="syncFirstStatus" style="margin-right: 10px">
            查询前先同步LIS
          </el-checkbox>
          <el-button
            type="primary"
            size="mini"
            class="commonBtn"
            @click="fetchExamineData"
            icon="el-icon-search"
          >
            查询
          </el-button>
          <el-button
            type="primary"
            size="mini"
            @click="saveOrAdviceList"
            icon="el-icon-s-promotion"
            class="commonBtn"
          >
            引入
          </el-button>
          <el-button
            type="primary"
            size="mini"
            @click="allSaveOrAdviceList"
            icon="el-icon-s-promotion"
            class="commonBtn"
          >
            全部引入
          </el-button>
        </template>
      </div>
      <div class="form-wrap" v-if="queryExamOrAdviceList.length > 0">
        <div class="adviceTable">
          <div class="adviceTableLabel">
            <div class="adviceTableHeader"></div>
            <div
              class="adviceTableLabelItem"
              v-for="(ele, index) in columnHeaderList"
              :key="'title' + index"
            >
              {{ ele.itemTitle }}{{ ele.itemUnit }}
            </div>
          </div>
          <div class="adviceTablePreviewLabel">
            <div class="adviceTableHeader">预览</div>
            <div
              class="adviceTableLabelItem"
              v-for="(ele, index) in columnPreviewList"
              :key="'title' + index"
            >
              {{ ele.itemValue }}
            </div>
          </div>
          <div class="adviceTableBox">
            <div
              class="adviceTableContent"
              v-for="(column, columnIndex) in queryExamOrAdviceList"
              :key="columnIndex"
            >
              <div
                @click="selectColumns(columnIndex)"
                class="adviceTableHeader"
                :class="column.defaultHighlight ? 'queryExamToday' : ''"
              >
                {{ column.defaultHighlight ? '✔️' : '' }}
                {{ column.examineDate.substr(0, 16) }}
              </div>
              <div
                class="adviceTableContentItem"
                v-for="(cell, cellIndex) in column?.items"
                :key="cellIndex + cell.uuid"
                :class="{ 'cell-pitch_on': cell._isSecect }"
                @click="handleItem(column, columnIndex, cell)"
              >
                {{ cell.itemValue }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-empty style="margin-top: 40px" v-else description="暂无数据" />
    </el-dialog>
    <el-dialog width="60%" title="检查报告导入" class="add-form" :visible.sync="PACSinnerVisible">
      <div class="dialog-btn-wrap">
        <template>
          按日期查询：
          <el-date-picker
            v-model="dialogQueryDate"
            type="daterange"
            align="center"
            size="mini"
            style="margin-right: 10px"
            unlink-panels
            @change="handleDialogQueryDate"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          ></el-date-picker>
          <el-checkbox v-model="syncFirstStatus" style="margin-right: 10px">
            查询前同步先检查报告
          </el-checkbox>
          <el-button
            type="primary"
            size="mini"
            class="commonBtn"
            @click="fetchPACSExamineData"
            icon="el-icon-search"
          >
            查询
          </el-button>
        </template>
      </div>
      <div class="form-wrap" v-if="PACSExamineData.length > 0">
        <el-table
          :data="PACSExamineData"
          class="PACSExamineTable"
          border
          :header-cell-style="{ background: '#eef1f6', color: '#606266', fontSize: '15px' }"
          height="100%"
        >
          <el-table-column
            prop="examineType"
            align="center"
            fixed="left"
            label="检查报告"
            width="180"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.examineType }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="description"
            align="center"
            label="检查结果"
            show-overflow-tooltip
            min-width="180"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.description }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="examineDate"
            align="center"
            label="检查时间"
            width="150"
          ></el-table-column>
          <el-table-column align="center" label="操作" width="120">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="savePACSOrAdviceList(scope.row)"
                icon="el-icon-s-promotion"
              >
                引入
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-empty v-else style="margin-top: 40px" description="暂无数据" />
    </el-dialog>
  </div>
</template>

<script>
import ReadonlyTab from './components/ReadonlyTab.vue'
import { uniqueJsonArrByField, getBeforeDate, getLatestExamineData } from '../../../utils'
export default {
  name: 'followAssessmentLEAVE',
  components: {
    ReadonlyTab
  },
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      activeName: '0',
      lastIndex: '0',
      list: [], //表单列表
      dataList: [],
      isReadonly: true,
      isResearch: true,
      innerVisibleAutoLeading: false,
      innerVisible: false,
      PACSinnerVisible: false,
      pacsTypeInnerVisible: false,
      syncFirstStatus: true,
      PACSOptions: [],
      PACSTypeCode: '',
      queryExamOrAdviceList: [],
      columnHeaderList: [], //列头
      columnPreviewList: [], // 预览
      dialogQueryDate: [getBeforeDate(7), getBeforeDate(0)],
      dialogQueryList: null,
      examineId: null,
      // 导入日期选择
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.setHours(0, 0, 0, 0))
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.setHours(0, 0, 0, 0))
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
              end.setTime(end.setHours(0, 0, 0, 0))
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      handleItemUuid: [],
      checkDataId: '',
      disabled: false,
      type: 'primary',
      loading: false,
      PACSExamineData: [],
      scrollTrigger: false, // 默认初始值
      handleItemList: []
    }
  },
  computed: {},
  watch: {
    isResearch: {
      handler(val) {
        if (val) {
          this.isReadonly = true
        } else {
          this.isReadonly = false
        }
      },
      immediate: true
    },
    info: {
      handler(val) {
        if (val && val.uuid) {
          this.getTable()
        }
      }
    }
  },
  mounted() {
    this.$bus.$emit('getUserFollowInfo')
    this.getPACSTypes()
  },
  created() {},
  methods: {
    getLabel(val) {
      if (val.alias) {
        return (
          val.alias + ' ' + (val.completion && val.completion !== 0 ? val.completion + '%' : '')
        )
      } else {
        return (
          val.formName + ' ' + (val.completion && val.completion !== 0 ? val.completion + '%' : '')
        )
      }
    },
    getHospitalTime() {
      if (this.info.admissionDate && this.info.dischargeDate) {
        const startTime = new Date(this.info.admissionDate)
        const endTime = new Date(this.info.dischargeDate)

        // 计算时间差（以毫秒为单位）
        const duration = endTime - startTime

        // 将毫秒转换为小时和分钟
        const days = Math.floor(duration / (1000 * 60 * 60 * 24))
        return `${days}天`
      } else {
        return '住院时长无法计算'
      }
    },
    getLeaveHospitalTime() {
      this.$api
        .post(
          `/v1/webconsole/study/operation/savetime/${this.info.uuid}?code=${'DISCHARGEDATE'}&date=${
            this.info.dischargeDate
          }`
        )
        .then(
          () => {
            if (this.info.studyEndDate && this.info.dischargeDate) {
              for (let i = 0; i < this.list.length; i++) {
                const listElement = this.list[i]
                if (listElement.examineTypeCode === 'DRUG') {
                  const dataListTab = this.dataList[Number(i)].tab
                  const element = dataListTab.find((el) => el.title.includes('后住院时长'))
                  if (element) {
                    const startTime = new Date(this.info.studyEndDate)
                    const endTime = new Date(this.info.dischargeDate)
                    const duration = endTime - startTime
                    const days = Math.floor(duration / (1000 * 60 * 60 * 24))
                    element.value = days
                    this.$set(dataListTab, dataListTab.indexOf(element), element)
                  }
                }
              }
            }

            this.$bus.$emit('saveFollowFormInfo')
            this.$bus.$emit('getUserFollowInfo')
            return this.$message.success('保存出院时间成功')
          },
          () => {
            return this.$message.error('保存出错')
          }
        )
    },

    scrollToTop() {
      if (this.scrollTrigger) {
        return
      }
      const container = document.querySelector('.body') // 选择合适的父元素
      if (container.scrollTop > 0) {
        let scrollTop = container.scrollTop
        let steep = scrollTop / 2000
        let timer = setInterval(() => {
          this.scrollTrigger = true
          // 滚动的速度逐渐变快，第一次走2000/1，然后减去已走的距离，下一次用剩下的距离再减去步进值，步进值也是不断变化，这样速度会越来越快
          scrollTop -= steep
          // 步进值不改变的话会匀速缓慢上滑，不断增加步进值上滑的距离增加，视觉效果速度变快
          steep += 20
          if (scrollTop <= 0) {
            clearInterval(timer)
            this.scrollTrigger = false
          }
          container.scrollTop = scrollTop
        }, 20)
      }
    },

    // 上传文件
    onUpload(formData) {
      this.$api
        .post(`/v1/webconsole/examination/import/leave/${this.$route.query.operationId}`, formData)
        .then(({ data: res }) => {
          if (res && res.data) {
            this.isResearch = false
            let newArr = JSON.parse(JSON.stringify(this.dataList[Number(this.activeName)].tab))
            newArr.map((item) => {
              res.data.newForm.map((items) => {
                if (item.id === items.id) {
                  if (items.value) {
                    item.value = items.value
                  }
                }
              })
            })
            this.dataList[Number(this.activeName)].tab = newArr
          }
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.examineId = res.data.examine.uuid
          this.dataList[Number(this.activeName)].examinationInfo.push(res.data.examine)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    delExamine(val) {
      let formData = new FormData()
      formData.append('examineId', val)
      this.$api
        .post(
          `/v1/webconsole/eform/delete/formdata/examine/${this.dataList[this.activeName].id}`,
          formData
        )
        .then(() => {
          this.getExamination()
        })
    },
    handleDownloadPdf() {
      this.$api
        .get(`/v1/webconsole/examination/eform/examination/${this.dataList[this.activeName].id}`)
        .then(({ data: res }) => {
          if (res && res.data && res.data[0]?.sourcePath) {
            // this.axios
            //   .get(`/v1/webconsole/nurseHandover/preview?filename=${res.data[0].sourcePath}`, {
            //     responseType: 'blob'
            //   })
            //   .then((res) => {
            //     if (res.data && res.data.size !== 0) {
            //       let blob = new Blob([res.data], {
            //         //type类型后端返回来的数据中会有，根据自己实际进行修改
            //         type: 'application/pdf'
            //       })
            //       let filename = this.list[this.activeName].alias + '.pdf'
            //       if (typeof window.navigator.msSaveBlob !== 'undefined') {
            //         window.navigator.msSaveBlob(blob, filename)
            //       } else {
            //         var blobURL = window.URL.createObjectURL(blob)
            //         // 创建隐藏<a>标签进行下载
            //         var tempLink = document.createElement('a')
            //         tempLink.style.display = 'none'
            //         tempLink.href = blobURL
            //         tempLink.setAttribute('download', filename)
            //         if (typeof tempLink.download === 'undefined') {
            //           tempLink.setAttribute('target', '_blank')
            //         }
            //         document.body.appendChild(tempLink)
            //         tempLink.click()
            //         document.body.removeChild(tempLink)
            //         window.URL.revokeObjectURL(blobURL)
            //       }
            //     }
            //   })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getExamination() {
      this.$api
        .get(`/v1/webconsole/examination/eform/examination/${this.dataList[this.activeName].id}`)
        .then((res) => {
          if (res.data && res.data.data) {
            this.dataList[Number(this.activeName)].examinationInfo = res.data.data
          } else {
            this.dataList[Number(this.activeName)].examinationInfo = []
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 导入pdf
    pdfImport(file) {
      let formData = new FormData()
      formData.append('formId', this.list[this.activeName].formId)
      formData.append('moduleCode', this.$route.query.moduleCode)
      formData.append('examTypeCode', this.PACSTypeCode)
      formData.append('file', file.file)
      this.onUpload(formData)
    },
    uniqueJsonArrByField,
    // 实验室数据引入
    autoLeading() {
      let formData = new FormData()
      if (this.dataList[this.activeName].id) {
        formData.append('formDataId', this.dataList[this.activeName].id)
      }
      if (this.syncFirstStatus) {
        formData.append('syncFirst', true)
      }
      this.$api
        .post(
          `/v1/webconsole/study/import/examination/leave/${this.info.uuid}/${
            this.list[this.activeName].formId
          }`,
          formData
        )
        .then(({ data: res }) => {
          if (res && res.data) {
            const elements = res.data.elements
            res.data.examinationIds.forEach((item) => {
              if (!this.handleItemUuid.includes(item)) {
                this.handleItemUuid.push(item)
              }
            })
            let newArr = JSON.parse(JSON.stringify(this.dataList[Number(this.activeName)].tab))
            newArr.map((item) => {
              item.elementId = item.id
              elements?.map((ele) => {
                if (item.id === ele.id) {
                  item.value = ele.value
                }
              })
            })
            this.dataList[Number(this.activeName)].isSave = false
            this.dataList[Number(this.activeName)].newTab = JSON.parse(
              JSON.stringify(this.dataList[Number(this.activeName)].tab)
            )
            setTimeout(() => {
              this.dataList[Number(this.activeName)].tab = newArr
            }, 200)
          }
        })
        .catch((err) => {
          console.log(err)
        })
      this.isResearch = false
      this.isReadonly = false
      this.innerVisibleAutoLeading = false
    },
    handleLeading(type) {
      let dischargeDate = null
      if (this.info.dischargeDate) {
        dischargeDate = this.info.dischargeDate
      } else {
        var currentDate = new Date()
        var year = currentDate.getFullYear()
        var month = String(currentDate.getMonth() + 1).padStart(2, '0')
        var day = String(currentDate.getDate()).padStart(2, '0')
        var hours = String(currentDate.getHours()).padStart(2, '0')
        var minutes = String(currentDate.getMinutes()).padStart(2, '0')
        var seconds = String(currentDate.getSeconds()).padStart(2, '0')
        var dateString =
          year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
        dischargeDate = dateString
      }
      var date = new Date(dischargeDate) // 转换为 Date 对象
      var timestamp = date.getTime() // 获取时间戳

      // 减去 7 天的毫秒数
      var subtractedTimestamp = timestamp - 14 * 24 * 60 * 60 * 1000

      var subtractedDate = new Date(subtractedTimestamp) // 创建新的 Date 对象
      var options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      var formattedDate = subtractedDate.toLocaleDateString('zh-CN', options).replace(/\//g, '-')
      this.dialogQueryDate = [
        this.info?.studyEndDate || formattedDate,
        this.info.dischargeDate || dischargeDate.substring(0, 10)
      ]
      this.dialogQueryList = this.dialogQueryDate.join(',')
      this.syncFirstStatus = true
      this.queryExamOrAdviceList = []
      this.handleItemUuid = []
      this.handleItemList = []
      if (type === 'auto') {
        this.innerVisibleAutoLeading = true
      } else {
        this.innerVisible = true
        this.fetchExamineData()
      }
    },
    getPACSTypes() {
      this.axios.get('/v1/webconsole/examination/list/examine_types').then(({ data: res }) => {
        if (res && res.data && res.data.length > 0) {
          let arr = []
          res.data.map((item) => {
            arr.push({
              label: item.examineTypeName,
              value: item.examineTypeCode
            })
          })
          this.PACSOptions = arr
        }
      })
    },
    handlePACSTemplet() {
      this.$refs['upload'].$refs['upload-inner'].handleClick()
      this.pacsTypeInnerVisible = false
    },
    handleUpPACS() {
      this.PACSTypeCode = ''
      this.pacsTypeInnerVisible = true
    },
    handlePACS() {
      let dischargeDate = null
      if (this.info.dischargeDate) {
        dischargeDate = this.info.dischargeDate
      } else {
        var currentDate = new Date()
        var year = currentDate.getFullYear()
        var month = String(currentDate.getMonth() + 1).padStart(2, '0')
        var day = String(currentDate.getDate()).padStart(2, '0')
        var hours = String(currentDate.getHours()).padStart(2, '0')
        var minutes = String(currentDate.getMinutes()).padStart(2, '0')
        var seconds = String(currentDate.getSeconds()).padStart(2, '0')
        var dateString =
          year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
        dischargeDate = dateString
      }
      var date = new Date(dischargeDate) // 转换为 Date 对象
      var timestamp = date.getTime() // 获取时间戳

      // 减去 7 天的毫秒数
      var subtractedTimestamp = timestamp - 14 * 24 * 60 * 60 * 1000

      var subtractedDate = new Date(subtractedTimestamp) // 创建新的 Date 对象
      var options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      var formattedDate = subtractedDate.toLocaleDateString('zh-CN', options).replace(/\//g, '-')
      this.dialogQueryDate = [
        this.info?.studyDate || formattedDate,
        this.info.dischargeDate || dischargeDate.substring(0, 10)
      ]
      this.dialogQueryList = this.dialogQueryDate.join(',')
      this.PACSExamineData = []
      this.syncFirstStatus = true

      this.PACSinnerVisible = true
    },
    fetchPACSExamineData() {
      let examineTypeCode = this.list[Number(this.activeName)].examineTypeCode
      let formData = {
        startDate: this.dialogQueryDate[0],
        stopDate: this.dialogQueryDate[1],
        examineTypeCode: examineTypeCode,
        syncFirst: this.syncFirstStatus ? true : false
      }
      this.$api
        .get(`/v1/webconsole/examination/patient/pacs/${this.info.patientsIndex}`, formData)
        .then(({ data: res }) => {
          if (res && res.data) {
            this.PACSExamineData = res.data
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    savePACSOrAdviceList(val) {
      if (this.info.patientsID) {
        this.axios
          .post(
            `/v1/webconsole/examination/import/examine/${val.uuid}?formId=${
              this.list[this.activeName].formId
            }`
          )
          .then(({ data: res }) => {
            if (res && res.data) {
              this.isResearch = false
              this.PACSinnerVisible = false
              let newArr = JSON.parse(JSON.stringify(this.dataList[Number(this.activeName)].tab))
              newArr.map((item) => {
                res.data.newForm.map((items) => {
                  if (item.id === items.id) {
                    if (items.value) {
                      item.value = items.value
                    }
                  }
                })
              })
              this.dataList[Number(this.activeName)].tab = newArr
            }
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.examineId = res.data.examine.uuid
            this.dataList[Number(this.activeName)].examinationInfo.push(res.data.examine)
          })
      }
    },
    // 日期更改
    handleDialogQueryDate() {
      if (!this.dialogQueryDate) {
        this.dialogQueryList = null
      } else {
        this.dialogQueryList = this.dialogQueryDate.join(',')
      }
    },
    getParentValue(val, arr) {
      for (let i = 0; i < arr.length; i++) {
        const element = arr[i]
        if (val.parentId === element.id) {
          if (val.parentValue) {
            element.value = val.parentValue
          }
        }
      }
    },
    async allSaveOrAdviceList() {
      let url = `/v1/webconsole/examination/import/lis/${this.info.patientsIndex}`
      let formData = new FormData()
      formData.append('formId', this.list[this.activeName].formId)
      formData.append('menuCode', this.$route.path.replace('/follow/', ''))
      formData.append('startDate', this.dialogQueryDate[0])
      formData.append('stopDate', this.dialogQueryDate[1])

      await this.$api.post(url, formData).then(({ data: res }) => {
        if (res && res.data && res.data.elements) {
          const elements = res.data.elements
          res.data.examinationIds.forEach((item) => {
            if (!this.handleItemUuid.includes(item)) {
              this.handleItemUuid.push(item)
            }
          })
          let newArr = JSON.parse(JSON.stringify(this.dataList[Number(this.activeName)].tab))
          newArr.map((item) => {
            item.elementId = item.id
            elements?.map((ele) => {
              if (item.id === ele.id) {
                item.value = ele.value
              }
            })
          })
          this.dataList[Number(this.activeName)].isSave = false
          this.dataList[Number(this.activeName)].newTab = JSON.parse(
            JSON.stringify(this.dataList[Number(this.activeName)].tab)
          )
          setTimeout(() => {
            this.dataList[Number(this.activeName)].tab = newArr
          }, 200)
        }
      })
      this.isResearch = false
      this.isReadonly = false
      this.innerVisible = false
    },
    async saveOrAdviceList() {
      this.handleItemList = getLatestExamineData(this.queryExamOrAdviceList)
      let newArr = JSON.parse(JSON.stringify(this.dataList[Number(this.activeName)].tab))
      newArr.map((item) => {
        item.elementId = item.id
        this.handleItemList?.map((items) => {
          if (item.elementId === items.elementId) {
            if (items.itemValue) {
              item.value = items.itemValue
            }
            if (item.parentId !== '-1') {
              this.getParentValue(item, newArr)
            }
          }
        })
      })
      this.dataList[Number(this.activeName)].isSave = false
      this.dataList[Number(this.activeName)].newTab = JSON.parse(
        JSON.stringify(this.dataList[Number(this.activeName)].tab)
      )
      setTimeout(() => {
        this.dataList[Number(this.activeName)].tab = newArr
      }, 200)
      if (this.handleItemUuid.length > 0) {
        const newArrId = []
        this.handleItemUuid.forEach((item) => {
          if (!newArrId.includes(item)) {
            newArrId.push(item)
          }
        })
        if (!this.dataList[this.activeName].id) {
          let arr = JSON.parse(JSON.stringify(this.dataList[Number(this.activeName)].tab))
          let dataList = {
            items: arr,
            examineIds: newArrId
          }
          dataList.items.forEach((item) => {
            item.elementId = item.id
            if (item.type === 'checkbox') {
              if (item.value?.length > 0) {
                item.value = item.value?.join(',')
              } else {
                item.value = null
              }
            }
          })
          let url = `/v1/webconsole/eform/leave/save/${this.$route.query.operationId}/${this.$route.query.moduleCode}`
          let data = {
            formId: '',
            id: '',
            ...dataList
          }
          data.formId = this.list[Number(this.activeName)].formId
          await this.$api.post(url, data).then((res) => {
            this.dataList[Number(this.activeName)].id = res.data.data.id
            if (res.data && res.data.data) {
              this.$api
                .get(`/v1/webconsole/examination/eform/examination/${res.data.data.id}`)
                .then((res) => {
                  if (res.data && res.data.data) {
                    this.dataList[Number(this.activeName)].examinationInfo = res.data.data
                  } else {
                    this.dataList[Number(this.activeName)].examinationInfo = []
                  }
                })
            }
          })
        }
      }
      this.isResearch = false
      this.isReadonly = false
      this.innerVisible = false
    },

    // 查询导入数据
    fetchExamineData() {
      let arr = this.list[Number(this.activeName)]
      if (this.dialogQueryList) {
        this.$api
          .get(`/v1/webconsole/examination/operation/lis/${this.info.patientsIndex}`, {
            startDate: this.dialogQueryDate[0],
            stopDate: this.dialogQueryDate[1],
            formId: arr.formId,
            menuCode: this.$route.path.replace('/follow/', ''),
            businessId: this.info.uuid,
            syncFirst: this.syncFirstStatus ? true : false
          })
          .then((res) => {
            if (res.data && res.data.data) {
              // res.data.data.sort(
              //   (b, a) => new Date(a.examineDate).getTime() - new Date(b.examineDate).getTime()
              // ) //时间升序
              this.queryExamOrAdviceList = res.data.data
              this.queryExamOrAdviceList.forEach((item) =>
                item.items.forEach((ite) => (ite._isSecect = false))
              )
              let newArr = []
              res.data.data.map((item) => {
                item.items.map((ele) => {
                  newArr.push(ele) // 取出所有的子项
                })
              })
              // 处理数据 保持同一长度
              this.columnHeaderList = this.uniqueJsonArrByField(newArr, 'itemTitle') || [] // 去重
              res.data.data.map((item) => {
                let newColumnHeaderList = JSON.parse(JSON.stringify(this.columnHeaderList))
                const newList = newColumnHeaderList.map((ele) => {
                  ele.itemValue = null
                  ele.uuid = parseInt(Math.random(0, 1) * 1000000)
                  item.items.map((thirdItem) => {
                    if (ele.itemTitle === thirdItem.itemTitle) {
                      ele = { ...thirdItem, uuid: ele.uuid }
                    }
                  })
                  return ele
                })
                item.items = newList
              })
              setTimeout(() => {
                this.columnPreviewList = JSON.parse(JSON.stringify(this.columnHeaderList))
                this.queryExamOrAdviceList.forEach((ele, index) => {
                  this.initColumns(index)
                })
              }, 200)
              this.isResearch = false
            }
          })
      } else {
        this.$message.error('日期不能为空')
      }
    },
    // handleItem(column, columnIndex, cell) {
    //   cell._isSecect = !cell._isSecect
    //   this.queryExamOrAdviceList.forEach((columns, i) => {
    //     if (columnIndex !== i) {
    //       columns.items.forEach((ele) => {
    //         if (cell.itemTitle === ele.itemTitle) {
    //           ele._isSecect = false
    //           if (
    //             ele.itemValue &&
    //             new Date(columns.examineDate) - new Date(column.examineDate) > 0 &&
    //             ele._isSecect
    //           ) {
    //             cell._isSecect = !cell._isSecect
    //           } else if (ele.itemValue && !cell.itemValue && cell._isSecect) {
    //             cell._isSecect = false
    //             ele._isSecect = true
    //           } else {
    //             ele._isSecect = false
    //           }
    //         }
    //       })
    //     }
    //   })

    //   this.handleItemUuid = column.items
    //     .filter((cell) => cell._isSecect)
    //     .map((cell) => cell.examinationId)

    //   this.$forceUpdate()
    // },

    handleItem(column, columnIndex, cell) {
      if (cell.itemValue) {
        cell._isSecect = !cell._isSecect
      }

      this.handleItemUuid = []

      this.queryExamOrAdviceList.forEach((columns, i) => {
        if (columnIndex !== i) {
          columns.items.forEach((ele) => {
            if (cell.itemTitle === ele.itemTitle && cell.itemValue) {
              ele._isSecect = false

              if (ele.itemValue) {
                if (
                  new Date(columns.examineDate) - new Date(column.examineDate) > 0 &&
                  ele._isSecect
                ) {
                  cell._isSecect = !cell._isSecect
                } else if (!cell.itemValue && cell._isSecect) {
                  cell._isSecect = false
                  ele._isSecect = true
                }
              }
            }
          })
        }
        this.handleItemUuid.push(
          ...columns.items.filter((cell) => cell._isSecect).map((cell) => cell.examinationId)
        )
      })

      this.columnPreviewList.forEach((item, index) => {
        if (item.itemTitle === cell.itemTitle && cell.itemValue) {
          const clonedItem = { ...cell }
          if (!cell._isSecect) {
            clonedItem.itemValue = null
          }
          this.$set(this.columnPreviewList, index, clonedItem)
        }
      })

      this.$forceUpdate()
    },

    selectColumns(i) {
      this.queryExamOrAdviceList.forEach((cln, cellIndex) => {
        cln.items.forEach((cell) => {
          if (i === cellIndex) {
            if (cell.itemValue) {
              this.handleItem(cln, i, cell)
            } else {
              cell._isSecect = false
            }
          }
        })
      })
      this.$forceUpdate()
    },

    initColumns(index) {
      const column = this.queryExamOrAdviceList[index]

      column.items.forEach((cell) => {
        if (cell.itemValue) {
          let shouldSelect = true
          this.queryExamOrAdviceList.forEach((otherColumn, otherIndex) => {
            if (index !== otherIndex) {
              otherColumn.items.forEach((otherCell) => {
                if (cell.itemTitle === otherCell.itemTitle && otherCell._isSecect) {
                  shouldSelect = false
                }
              })
            }
          })

          if (shouldSelect) {
            cell._isSecect = true
            this.columnPreviewList.forEach((item, index) => {
              if (item.itemTitle === cell.itemTitle) {
                item.itemValue = cell.itemValue
              }
            })
          }
        }
      })

      this.handleItemUuid.push(
        ...column.items.filter((cell) => cell._isSecect).map((cell) => cell.examinationId)
      )
      this.$forceUpdate()
    },
    handleClick() {
      this.handleItemUuid = []
      this.examineId = null
      this.isResearch = true
      if (this.dataList[this.activeName].id) {
        this.getExamination()
      } else {
        this.dataList[Number(this.activeName)].examinationInfo = []
      }
      if (this.dataList[this.lastIndex].isSave === false) {
        this.dataList[Number(this.lastIndex)].tab = JSON.parse(
          JSON.stringify(this.dataList[Number(this.lastIndex)].newTab)
        )
      }
      eval('this.$refs.tab' + this.lastIndex)[0].isEdit()
      this.lastIndex = this.activeName
    },
    getDRUGTime() {
      const dataListTab = this.dataList[Number(this.activeName)].tab
      const element = dataListTab.find((el) => el.title.includes('后住院时长'))
      if (element) {
        const startTime = new Date(this.info.studyEndDate)
        const endTime = new Date(this.info.dischargeDate)
        const durationMilliSeconds = endTime - startTime
        const days = Math.floor(durationMilliSeconds / (1000 * 60 * 60 * 24))
        element.value = days
        this.$set(dataListTab, dataListTab.indexOf(element), element)
      }
    },
    changeBasicInfo() {
      this.isResearch = !this.isResearch
      if (this.isResearch) {
        eval('this.$refs.tab' + this.activeName)[0].isEdit()
      }
      if (this.dataList[this.activeName].id) {
        this.getExamination()
      } else {
        this.dataList[Number(this.activeName)].examinationInfo = []
      }
      this.handleItemUuid = []
    },
    isLoading() {
      this.type = 'primary'
      this.disabled = false
      this.loading = false
    },
    saveEFormInfo(val) {
      val.items.forEach((item) => {
        item.elementId = item.id
      })
      let url = `/v1/webconsole/eform/leave/save/${this.$route.query.operationId}/${this.$route.query.moduleCode}`
      let data = {
        formId: '',
        id: '',
        ...val
      }
      if (this.examineId) {
        data.examineIds.push(this.examineId)
      }
      if (this.dataList[Number(this.activeName)]?.id) {
        data.formId = this.dataList[Number(this.activeName)].formId
        data.id = this.dataList[Number(this.activeName)].id
      } else {
        data.formId = this.list[Number(this.activeName)].formId
      }

      this.$api.post(url, data).then(
        (res) => {
          this.isResearch = true
          this.disabled = false
          this.type = 'primary'
          this.$message.success(res.data.msg)
          this.dataList[this.activeName].isSave === true
          if (Number(this.activeName) + 1 < this.list.length) {
            this.activeName = (Number(this.activeName) + 1).toString()
            this.changeBasicInfo()
          }
          this.getTable()
          this.scrollToTop()
          this.$bus.$emit('saveFollowFormInfo')
        },
        () => {
          this.loading = false
        }
      )
    },
    saveBasicInfo() {
      this.disabled = true
      this.loading = true
      this.type = 'info'
      eval('this.$refs.tab' + this.activeName)[0].Save()
    },
    // 获取table列表
    getTable() {
      if (Object.keys(this.info).length) {
        let url = `/v1/webconsole/eform/leave/form/${this.$route.query.operationId}/${this.$route.query.moduleCode}`
        this.$api.get(url).then(({ data: res }) => {
          if (res && res.data) {
            this.dataList = []
            this.list = res.data

            const promise = res.data.map(async (item) => {
              let url = `/v1/webconsole/eform/leave/formdata/${this.$route.query.operationId}/${this.$route.query.moduleCode}`
              let data = {
                formId: item.formId
              }
              const res = await this.$api.get(url, data)
              return res.data.data
            })
            Promise.all(promise)
              .then((result) => {
                result.map((item) => {
                  if (item.length > 0) {
                    this.dataList.push({
                      ...item[0],
                      examinationInfo: [],
                      tab: []
                    })
                  } else {
                    this.dataList.push({
                      examinationInfo: [],
                      tab: []
                    })
                  }
                })
              })
              .then(() => {
                this.getTableData()
                this.loading = false
              })
          }
        })
      }
    },
    getTableData() {
      for (let i = 0; i < this.dataList.length; i++) {
        const element = this.dataList[i]
        if (element.id) {
          let url = `/v1/webconsole/eform/elements/${element.formId}/${element.id}`
          this.$api.get(url).then((res) => {
            if (res.data && res.data.data) {
              this.$set(element, 'tab', res.data.data)
            }
          })
        } else {
          let url = '/v1/webconsole/eform/newform'
          let data = {
            formId: this.list[i].formId
          }
          this.$api.get(url, data).then((res) => {
            if (res.data && res.data.data) {
              this.$set(element, 'tab', res.data.data)
            }
          })
        }
      }
      if (this.dataList[this.activeName].id) {
        this.getExamination()
      }
    },
    getQueryInfo2() {
      this.$router.push({
        path: `LeaveHospitalBaseInspectionRecords?moduleCode=${this.$route.query.moduleCode}&operationId=${this.$route.query.operationId}`
      })
    }
  }
}
</script>
<style lang="less">
.el-tooltip__popper {
  max-width: 700px;
}
</style>
<style lang="less" scoped>
.main-content {
  background: #eff0f7;
}
.add-pacs {
  /deep/.el-dialog {
    // width: 1000px !important;
    background: #f8f8f8;

    .el-dialog__header {
      background: #2d507e;
      padding: 10px;
      .el-dialog__title {
        color: #fff;
      }
      .el-dialog__close {
        color: #fff;
      }
      .el-dialog__headerbtn {
        top: 0.9rem;
      }
    }
  }
}
.container {
  .crt-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .crt-content-left {
      margin-left: 20px;

      height: 28px;
      line-height: 28px;
      width: 55%;
      display: flex;
      .block {
        margin-left: 10px;
        .el-input {
          width: 160px;
        }
        .demonstration {
          margin: 0 10px;
        }
      }
    }
    .crt-content-right {
      display: flex;
      margin-right: 20px;
      height: 28px;
      line-height: 28px;
    }
  }
  .body::-webkit-scrollbar {
    width: 12px;
  }
  .body::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  .body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }
  .body::-webkit-scrollbar-thumb:window-inactive {
    background: #ccc;
  }
  .body {
    flex: 1;
    overflow: auto;
    margin-top: 10px;
    margin-left: 10px;
    border-top-left-radius: 8px;
    /deep/.el-card__body {
      padding: 0 0 0 10px;
    }
  }
  /deep/.el-tabs {
    margin-top: 5px;
    .el-tabs__header {
      margin: 0;
    }
    .el-tabs__active-bar {
      background: #2d507e;
      border: #2d507e;
    }
    .el-tabs__item.is-active {
      color: #2d507e;
    }
    .el-tabs__item:hover {
      color: #427bc0;
    }
    .el-tabs__item {
      font-size: 14px;
      height: 40px;
      line-height: 40px;
    }
  }
}
.form-wrap {
  margin-top: 80px;
  padding: 0 20px;
  height: 500px;
  overflow-y: auto;

  .adviceTable {
    display: flex;
    margin: 15px 0;
    .adviceTableLabel {
      white-space: nowrap;
      background-color: #aae1fe;
      .adviceTableHeader {
        margin: 1px -1px -1px 1px;
        background-color: #00a5ff;
        border-top: 1px solid #4c6fe2;
        border-bottom: 1px solid #4c6fe2;
        border-left: 1px solid #4c6fe2;
        border-right: 1px solid #4c6fe2;
        height: 30px;
      }
      .adviceTableLabelItem {
        margin: 1px -1px -1px 1px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-bottom: 1px solid #4c6fe2;
        border-right: 1px solid #4c6fe2;
        border-left: 1px solid #4c6fe2;
      }
    }
    .adviceTablePreviewLabel {
      text-align: center;
      min-width: 120px;
      .adviceTableHeader {
        margin: 1px -1px -1px 1px;
        background-color: #00a5ff;
        border-top: 1px solid #4c6fe2;
        border-bottom: 1px solid #4c6fe2;
        border-right: 1px solid #4c6fe2;
        height: 30px;
        line-height: 30px;
        font-weight: bold;
        color: #fff;
      }
      .adviceTableLabelItem {
        margin: 1px -1px -1px 1px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-bottom: 1px solid #4c6fe2;
        border-right: 1px solid #4c6fe2;
      }
    }
    .adviceTableBox {
      display: flex;
      .adviceTableContent {
        min-width: 140px;
        flex-shrink: 0;
        .adviceTableHeader {
          padding: 0 10px;
          margin: 1px -1px -1px 1px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background-color: #00a5ff;
          border-top: 1px solid #4c6fe2;
          border-bottom: 1px solid #4c6fe2;
          border-right: 1px solid #4c6fe2;
        }
        .queryExamToday {
          background-color: #5aff009c !important;
        }
        .adviceTableContentItem {
          margin: 1px -1px -1px 1px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-bottom: 1px solid #4c6fe2;
          border-right: 1px solid #4c6fe2;
          cursor: pointer;
        }
      }

      .cell-pitch_on {
        background-color: #aae1fe;
      }
      .adviceTableContentItem:hover {
        margin: 1px -1px -1px 1px;
        background: #d2eeff;
      }
    }
  }
  .el-divider__text {
    background-color: #f8f8f8;
  }
  .el-row {
    margin-bottom: 15px;
  }
  .el-input {
    width: 250px;
  }
  .el-input__inner {
    border-color: #dcdfe6;
    height: 35px;
  }
  .el-tabs__header {
    position: fixed;
    z-index: 100;
    background: #fff;
  }
}
.auto-leading {
  /deep/.el-dialog {
    width: 30% !important;
    .el-dialog__body {
      height: 200px;
    }
  }
}
</style>
